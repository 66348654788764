<template>
    <div class="help widthCen content">
        <h6>学车视频 > {{tabs[isTab]}}</h6>
        <!-- 内容开始 -->
        <div class="video_width">
            <!-- 左边 -->
            <div class="video_left">
                <div class="video_left_div">
                    <!-- 头部 -->
                    <div class="video_left_head zhong float">
                        <div class="video_head_title zhong">
                            <p
                                v-for="(item,index) in tabs"
                                :key="index"
                                :class="isTab == index? 'active' : ''"
                                @click="tabClick(index)"
                            >{{item}}</p>
                            <!-- <p :class="isTab == 2 ? 'active' : ''" @click="tabClick(2)">科目二</p>
              <p :class="isTab == 3 ? 'active' : ''" @click="tabClick(3)">科目三</p>-->
                        </div>
                    </div>
					<div class="video_left_head">
						<div class="video_head_right zhong">
							<h6>车型:</h6>
							<hgroup class="zhong">
								<p
									v-for="(item,index) in car.car"
									:key="index"
									:class="isClassify == item.Id ? 'active' : ''"
									@click="classifyClick( item.Id)"
								>{{item.Name}}</p>
							</hgroup>
						</div>
					</div>
                    <!-- 标签 -->
                    <div
                        class="video_label animated fadeIn"
                        v-show="isTab == 1 || isTab == 2"
                    >
                        <div
                            v-for="(item, index) in tips"
                            :key="index"
                            class="video_label_width"
                            @click="labelClick(index)"
                        >
                            <div class="video_label_fg zhong center">
                                <img :src="item.ImgUrl || require('../../images/seat.png')" />
                                <p>{{item.CategoryName}}</p>
                            </div>
                        </div>
                    </div>

                    <!-- 列表 -->
                    <video-list :a="list"></video-list>

                    <!-- 分页 -->
                    <div class="artice_page zhong center">
                        <el-pagination
                            :current-page.sync="rq.page"
                            :page-size="rq.pageSize"
                            background
                            layout="prev, pager, next"
                            :total="totalCount"
                            @current-change="handleCurrentChange"
                        ></el-pagination>
                    </div>
                </div>
            </div>

            <!-- 右边 -->
            <div class="video_right">
                <div class="video_right_div">
                    <div class="video_right_head zhong">
                        <img src="../../images/pai.png" />
                        <p>播放排行</p>
                    </div>

                    <div class="video_ranking">
                        <ul>
                            <li
                                class="float zhong"
                                v-for="(item, index) in rankList"
                                :key="index"
                                @click="$router.push({ name: 'videoDtails', query: { id: item.Id } })"
                            >
                                <div class="video_right_hg zhong">
                                    <div
                                        v-show="index===0"
                                        class="ranking_left"
                                    >
                                        <img :src="item.ImageUrl || require('../../images/seat.png')" />
                                        <span>1</span>
                                    </div>

                                    <span v-if="index === 1">{{index+1}}</span>
                                    <span
                                        v-else-if="index === 2"
                                        class="spanColor"
                                    >{{index+1}}</span>
                                    <span
                                        v-else-if="index > 2"
                                        class="spanBg"
                                    >{{ index+1}}</span>

                                    <p :class="index===0?'p-width':''">{{ item.Title }}</p>
                                </div>
                                <span>{{ item.Number }}次播放</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <!-- 清楚浮动 -->
            <div class="cf"></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@/styles/public.scss';
@import '@/styles/video.scss';
</style>
<script>
import videoList from '../../components/videoList.vue';
import { mapState } from 'vuex';
export default {
    name: '',
    components: {
        videoList
    },
    data() {
        return {
            isTab: 0,
            isClassify: -1,
            tabs: ['全部', '科目二', '科目三'],
            isLabel: 0,
            rankList: [],
            tips: [],
            list: [],
            rq: {
                page: 1,
                pageSize: 6,
                type: 5,
                carId: 0
            },
            totalCount: 0,
            cars: []
        };
    },
    computed: {
        ...mapState(['car'])
    },
    created() {
        this.getVideo();
        this.getVideoSort();
    },
    methods: {
        tabClick(index) {
            this.isTab = index;
            this.rq.type = index + 1;
            if (index === 0) {
                this.rq.type = 5;
            }
            this.getVideo();
            if (index > 0) {
                this.$api.getTips(index + 1).then(res => {
                    this.tips = res.data;
                });
            }
        },
        // 分类
        classifyClick(index) {
            this.isClassify = index;
            this.rq.carId = index;
            this.getVideo();
        },
        // 标签
        labelClick(index) {
            this.isLabel = index;
            var id = this.tips[index].Id;
            this.$router.push({ name: 'videoArticle', query: { id: id } });
        },
        /**
         * 播放排行
         */
        getVideoSort() {
            this.$api.getVideoSort(2).then(res => {
                this.rankList = res.data;
            });
        },
        /**
         * 视频列表
         */
        getVideo() {
            this.$api.getVideo(this.rq).then(res => {
                this.totalCount = res.TotalCount;
                this.list = res.data;
            });
        },
        /**
         * 跳页
         */
        handleCurrentChange() {
            this.getVideo();
        }
    }
};
</script>
