<template>
    <!-- 列表 -->
    <div class="video_list">
        <ul>
            <li
                v-for="(item, index) in a"
                :key="index"
                @click="details(item.Id)"
            >
                <div class="video_div">
                    <img
                        width="100%"
                        height="100%"
                        :src="item.ImageUrl || require('../images/seat.png')"
                    />
                </div>
                <p>{{ item.Title }}</p>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: '',
    components: {},
    props: {
        a: {
            type: Array,
            default: () => []
        },
        type: String
    },
    data() {
        return {
            list: []
        };
    },
    methods: {
        details(id) {
            this.$router.push({ name: 'videoDtails', query: { id: id } });
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/videoList.scss';
</style>
